import React from 'react'
import { connect } from 'react-redux'

import Paragraph from '../../../../uiKit/texts/Paragraph'
import SubmitButton from '../../../../uiKit/buttons/SubmitButton'
import { alertSuccess } from '../../../../api'
import { AiChatSettingsType } from '../../../../models/WidgetSettingsTypes'

import classes from './styles.module.scss'

interface AiChatCopySnippetProps {
  aiChatSettings: AiChatSettingsType
  activeBot: any
}

const AiChatCopySnippet: React.FC<AiChatCopySnippetProps> = ({ aiChatSettings, activeBot }) => {
  // eslint-disable-next-line max-len
  const getSrcAttr = (): string => aiChatSettings?.templateUrl + `/?publicBotIdentifier=${activeBot.publicIdentifier}&backendURL=${aiChatSettings.backendUrl}`

  // eslint-disable-next-line max-len
  const scriptSnipped = `<iframe id="botscrew-chat-frame" src="${getSrcAttr()}" style="min-width: 100%; width: 100%; height: 450px; border: 0"></iframe>`

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(scriptSnipped)
    alertSuccess('Copied!')
  }

  return (
    <div>
      <Paragraph style={{ margin: '4px 0 8px 0' }}>Copy AI chat code snippet to clipboard</Paragraph>
      <pre className={classes.snippet}>
        {
          // eslint-disable-next-line max-len
          `<iframe id="botscrew-chat-frame"\nsrc="${getSrcAttr()}" style="min-width: 100%; width: 100%; height: 450px; border: 0">\n</iframe>`
        }
      </pre>
      <SubmitButton title="Copy to clipboard" onClick={handleCopyToClipboard} />
    </div>
  )
}

const mapStateToProps = (state: { activeBot: any; aiChatSettings: AiChatSettingsType }) => ({
  activeBot: state.activeBot,
  aiChatSettings: state.aiChatSettings,
})

export default connect(mapStateToProps)(AiChatCopySnippet)
