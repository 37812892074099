import React from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '32px',
    backgroundColor: 'white',
    textAlign: 'center',
  },
  imageNotFound: {
    width: '80px',
    height: '80px',
  },
  titleClass: {
    fontSize: '18px',
    lineHeight: 1,
    color: theme.tabs.login.unavailableSmall.titleClassText,
    margin: '64px 0 16px',
  },
  paragraphClass: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 'normal',
    margin: 0,
    color: theme.tabs.login.unavailableSmall.paragraphClassText(),
  },
  button: {
    height: '54px',
    borderRadius: '10px',
    border: 'none',
    fontWeight: 300,
    lineHeight: 'normal',
    fontSize: '16px',
    textAlign: 'center',
    padding: '0 25px',
    color: theme.tabs.login.unavailableSmall.buttonText,
    cursor: 'pointer',
    background: theme.tabs.login.unavailableSmall.button(),
    boxShadow: '0 3px 9px rgba(19, 69, 186, 0.206267)',
    margin: '32px 0 0',
    '&:hover': {
      transform: 'scale(1.05)',
      transition: '.1s ease-in-out',
    },
  },
})

const UnavailableSmall = ({
  classes,
  buttonTitle,
  buttonUrl,
  title,
  subtitle,
  history,
}) => {
  const handleButtonClick = () => {
    history.push(buttonUrl)
  }

  return (
    <main className={classes.main}>
      <img className={classes.imageNotFound} src="/images/platform/link.png" />

      <h1 className={classes.titleClass}>{title}</h1>

      {subtitle && <p className={classes.paragraphClass}>{subtitle}</p>}

      <button className={classes.button} onClick={handleButtonClick}>
        {buttonTitle}
      </button>
    </main>
  )
}

export default withRouter(
  withStyles(styles, { withTheme: true })(UnavailableSmall),
)
