import React from 'react'
import { withStyles } from '@material-ui/core'

import GenericMessage from '../GenericMessage/GenericMessage'
import Slider from 'uiKit/slider/Slider'

import { styles } from './styles'
import { RIBBON_BLUE } from 'constants/colors'
import { PayloadType } from 'models/ChatMessagesTypes'

interface Props {
  classes: any
  payload: PayloadType
  isRtl: boolean
  highlight: boolean
  postbackIds: string[]
}

const CarouselMessage: React.FC<Props> = props => {
  const { classes, payload, isRtl, highlight, postbackIds } = props

  return (
    <Slider itemLength={payload.elements?.length}>
      <div className={classes.genericContainer} style={{ border: highlight && `1px solid ${RIBBON_BLUE}` }}>
        {payload.elements?.map((element, index) => (
          <GenericMessage element={element} isRtl={isRtl} key={index} postbackIds={postbackIds} />
        ))}
      </div>
    </Slider>
  )
}

export default withStyles(styles)(CarouselMessage)
