import classes from './styles.module.scss'
import { RIBBON_BLUE } from '../../constants/colors'
import React from 'react'

interface Props {
  color?: string
  turnOffPointer?: boolean
  label: string
  checked: boolean
  onChange: (a: boolean) => void
}

const Checkbox: React.FC<Props> = ({ color, label, checked, onChange, turnOffPointer }) => {
  const style: { [key: string]: string } = {
    '--theme-color': color || RIBBON_BLUE,
  }

  const cursorStyle = turnOffPointer ? { cursor: 'auto' } : {}

  const handleChange = () => {
    onChange && onChange(!checked)
  }

  return (
    <div className={classes.container} style={cursorStyle} onClick={handleChange}>
      {label}
      <input type="checkbox" checked={checked} onChange={handleChange}/>
      <span className={classes.checkmark} style={style} />
    </div>
  )
}

export default Checkbox
