import React, { useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import ButtonDetailsModal from 'uiKit/PostbackModal/ButtonDetailsModal'
import { ButtonInfoIcon } from 'uiKit/icons/ButtonInfoIcon'
import { ButtonUrlIcon } from 'uiKit/icons/ButtonUrlIcon'
import { styles } from './styles'
import Tooltip from 'uiKit/StyledTooltip/StyledTooltip'
import { theme } from 'constants/theme'

const Buttons = (props) => {
  const { classes, buttons, postbackIds } = props
  const [openStates, setOpenStates] = useState(new Array(buttons.length).fill(false))
  const [anchorEl, setAnchorEl] = useState(null)
  const { atomButtons } = theme.tabs.support

  const handleOpenModal = (index, event) => {
    setAnchorEl(event.currentTarget)
    const newOpenStates = [...openStates]
    newOpenStates[index] = true
    setOpenStates(newOpenStates)
  }

  const handleCloseModal = (index) => {
    setAnchorEl(null)
    const newOpenStates = [...openStates]
    newOpenStates[index] = false
    setOpenStates(newOpenStates)
  }

  const handleUrlRedirect = (redirectUrl) => {
    window.open(redirectUrl.toString(), '_blank')
  }

  const isReplyPressed = (button) => {
    return button.postbackId && postbackIds.includes(button.postbackId)
  }

  return (
    <div className={classes.wrap}>
      {buttons.map((button, index) => (
        <React.Fragment key={index}>
          <div
            className={classes.button}
            style={{
              backgroundColor: isReplyPressed(button) && atomButtons.backgroundPressed,
              borderColor: isReplyPressed(button) && atomButtons.borderPressed,
              borderRadius: index === buttons.length - 1 && '0px 0px 8px 8px',
            }}
          >
            <span className={classes.title} style={button.url ? { marginLeft: '45px' } : {}}>{button.title}</span>
            <div className={classes.icons}>
              {button.postbackId && button.url && (
                <Tooltip title='Web button. Open link' placement='top'>
                  <span style={{ cursor: 'pointer' }} onClick={() => handleUrlRedirect(button.url)}>
                    <ButtonUrlIcon />
                  </span>
                </Tooltip>
              )}
              {button.postbackId && (
                <Tooltip
                  classes={{tooltip: classes.tooltip}}
                  title={button.url ? 'Web button' : 'Postback button'}
                  placement='top'>
                  <span style={{ cursor: 'pointer' }} onClick={(event) => handleOpenModal(index, event)}>
                    <ButtonInfoIcon/>
                  </span>
                </Tooltip>
              )}
            </div>
          </div>
          <ButtonDetailsModal
            btn={button}
            open={openStates[index]}
            onClose={() => handleCloseModal(index)}
            anchorEl={anchorEl}
          />
        </React.Fragment>
      ))}
    </div>
  )
}

Buttons.propTypes = {
  classes: PropTypes.object,
  buttons: PropTypes.array,
}

export default withStyles(styles)(Buttons)
