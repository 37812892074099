import { Tooltip, withStyles } from '@material-ui/core'

const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: '#393939',
    color: 'white',
    fontSize: '16px',
    borderRadius: '10px',
    padding: '8px',
  },
})(Tooltip)

export default StyledTooltip
