import styled from 'styled-components'
import { theme } from 'constants/theme'

const QuickReply = styled.button<{ isReplyPressed: boolean; isSubmitButton: boolean }>`
  margin: 2px 0px 3px 5px;
  display: flex;
  justify-content: center;
  padding: 8px 16px;
  cursor: pointer;
  background: ${({ isReplyPressed, isSubmitButton }) =>
    (isSubmitButton
      ? theme.tabs.support.quickReply.backgroundSubmit
      : isReplyPressed
        ? theme.tabs.support.quickReply.backgroundPressed
        : theme.tabs.support.quickReply.background)};
  border: 1px solid ${({ isReplyPressed, isSubmitButton }) =>
    (isSubmitButton
      ? theme.tabs.support.quickReply.borderSubmit
      : isReplyPressed
        ? theme.tabs.support.quickReply.borderPressed
        : theme.tabs.support.quickReply.border)};
  color: ${({ isSubmitButton }) =>
    (isSubmitButton
      ? theme.tabs.support.quickReply.textSubmit
      : theme.tabs.support.quickReply.text)};
  box-sizing: border-box;
  border-radius: 26px;
  line-break: loose;
  user-select: none;
  line-height: 1.2;
  outline: none;
  word-break: break-word;
`

export { QuickReply }
