import { createMuiTheme } from '@material-ui/core/'
import {
  WHITE, RIBBON_BLUE, CORNFLOWER_BLUE, FIORD_BLUE, COMET_GREY, SPUN_PEARL_GREY,
  SELAGO_GRAY, GHOST_GRAY, JORDY_BLUE, PERMISSION_RED, ATHENS_GRAY_DARK, MISCHKA_GRAY,
  GRAY, OXFORD_BLUE, DEEP_SKY_BLUE, ROYAL_BLUE, CADET_BLUE, MERCURY, PASTEL_ORANGE,
  MALACHITE_GREEN, BLUEBERRY_BLUE, YELLOW_ORANGE, MINE_SHAFT, PERIWINKLE, PLATINUM,
  SHARK_BLACK, GAINSBORO
} from 'constants/colors'

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: CORNFLOWER_BLUE,
      medium: BLUEBERRY_BLUE,
      main: RIBBON_BLUE,
    },
  },
  defaults: {
    buttonText: WHITE,
    buttonBackground: RIBBON_BLUE,
    buttonBackgroundLight: CORNFLOWER_BLUE,
    headerText: FIORD_BLUE,
    primaryText: COMET_GREY,
    grayText: SPUN_PEARL_GREY,
    background: SELAGO_GRAY,
    gray: GHOST_GRAY,
    contrastText: WHITE,
    error: PERMISSION_RED,
    iconsGray: MISCHKA_GRAY,
    success: MALACHITE_GREEN,
    warning: PASTEL_ORANGE,
    text: COMET_GREY,
    grayColor: MISCHKA_GRAY,
    textHeader: FIORD_BLUE,
    textGray: SPUN_PEARL_GREY,
    pieChartColors: {
      chatbot: RIBBON_BLUE,
      support: YELLOW_ORANGE,
    },
  },
  menu: {
    tabList: () => 'linear-gradient(359.25deg, ' + theme.defaults.buttonBackgroundLight +
      ' 49.98%, ' + theme.defaults.buttonBackground + ' 149.98%)',
    tabListItemActive: 'rgba(19,69,186,0.5)',
    tabListItemHover: 'rgba(19,69,186,0.5)',
    tabListIcon: JORDY_BLUE,
    tabListIconActive: WHITE,
    helpIcon: WHITE,
    helpText: WHITE,
    background: () => theme.defaults.background,
    root: () => theme.defaults.background,
    toolbar: WHITE,
    homeButtonText: () => theme.palette.primary.main,
    testButton: () => 'linear-gradient(285.2deg, ' + theme.defaults.buttonBackgroundLight +
      ' -35.9%, ' + theme.defaults.buttonBackground + ' 61.17%)',
    testButtonText: () => theme.defaults.contrastText,
  },
  uikit: {
    paragraph: {
      text: COMET_GREY,
    },
  },
  tabs: {
    login: {
      buttonText: () => theme.defaults.buttonText,
      buttonBackground: () => theme.defaults.buttonBackground,
      imageBackground: () => theme.defaults.background,
      formTitle: () => theme.defaults.headerText,
      eyeIcon: () => theme.palette.primary.main,
      input: {
        border: MISCHKA_GRAY,
        borderFocus: () => theme.palette.primary.main,
        borderError: PERMISSION_RED,
        placeholderText: () => theme.defaults.grayText,
        label: () => theme.defaults.primaryText,
        errorLabel: PERMISSION_RED,
      },
      forgotPassword: {
        formTitle: () => theme.defaults.headerText,
        formSubtitle: () => theme.defaults.primaryText,
        noteText: () => theme.defaults.primaryText,
        link: GRAY,
      },
      afterSighUp: {
        title: () => theme.defaults.headerText,
        text: COMET_GREY,
        emailText: () => theme.palette.primary.main,
        background: () => theme.defaults.background,
        containerBackground: WHITE,
      },
      unavailableSmall: {
        titleClassText: FIORD_BLUE,
        paragraphClassText: () => theme.defaults.primaryText,
        buttonText: WHITE,
        button: () => theme.defaults.buttonBackground,
      }
    },
    home: {
      headerBackground: () => theme.defaults.contrastText,
      background: () => theme.defaults.background,
      text: () => theme.defaults.headerText,
      buttonText: () => theme.palette.primary.main,
      botPlate: {
        menuBorder: MISCHKA_GRAY,
        menuButtonText: FIORD_BLUE,
        menuButtonHover: 'rgba(90, 152, 247, 0.1)',
        botBackground: WHITE,
        botText: FIORD_BLUE,
        createBotBackground: () => 'linear-gradient(325.02deg, ' + theme.defaults.buttonBackgroundLight +
          ' 0%, ' + theme.defaults.buttonBackground + ' 100%)',
        createBotText: () => theme.defaults.contrastText,
        createBotErrorText: () => theme.defaults.error,
      },
    },
    accountSettings: {
      background: () => theme.defaults.background,
      titleText: OXFORD_BLUE,
      inputBackground: WHITE,
    },
    dateButton: {
      color: WHITE,
      colorSelected: () => theme.defaults.buttonBackgroundLight,
      border: ATHENS_GRAY_DARK,
      borderSelected: () => theme.defaults.buttonBackgroundLight,
      text: () => theme.defaults.primaryText,
      textSelected: WHITE,
    },
    flows: {
      newFlowBackground: WHITE,
      flowListItems: WHITE,
      flowListItemsText: () => theme.defaults.headerText,
      flowListItemsSubtitle: () => theme.defaults.primaryText,
      newFlowButton: () => 'linear-gradient(312.17deg, ' + theme.defaults.buttonBackgroundLight +
        ' 3.33%, ' + theme.defaults.buttonBackground + ' 96.71%)',
      newFlowButtonPlusIcon: WHITE,
      newFlowText: FIORD_BLUE,
      deleteFlowButton: WHITE,
      deleteFlowButtonBorder: ATHENS_GRAY_DARK,
      deleteFlowButtonIcon: SPUN_PEARL_GREY,
      headerText: () => theme.defaults.headerText,
      headerFontFamily: 'Lato, sans-serif',
      searchBorderColor: GHOST_GRAY,
      searchIcon: MISCHKA_GRAY,
    },
    atoms: {
      newAtomText: () => theme.palette.primary.main,
      newAtomBorder: () => theme.palette.primary.main,
      newAtomPlusIcon: () => theme.palette.primary.main,
      newAtomBackground: WHITE,
      newAtomInputBorder: PERMISSION_RED,
      newAtomInputBorderError: GHOST_GRAY,
      border: ATHENS_GRAY_DARK,
      background: WHITE,
      text: () => theme.defaults.primaryText,
      textHover: () => theme.defaults.primaryText,
      activeText: WHITE,
      activeBackground: CORNFLOWER_BLUE,
      activeBorder: CORNFLOWER_BLUE,
      activeDeleteIcon: WHITE,
    },
    atomEditor: {
      saveAtomButton: () => 'linear-gradient(285.2deg, ' + theme.defaults.buttonBackgroundLight +
        ' -35.9%, ' + theme.defaults.buttonBackground + ' 61.17%)',
      background: WHITE,
      elementIcon: CORNFLOWER_BLUE,
      textMessage: {
        button: () => theme.defaults.buttonBackgroundLight,
        buttonHover: WHITE,
        buttonText: WHITE,
        buttonHoverText: DEEP_SKY_BLUE,
      },
      genericTemplate: {
        buttonText: DEEP_SKY_BLUE,
      },
      quickReply: {
        ovalIcon: RIBBON_BLUE,
        border: ROYAL_BLUE,
        text: ROYAL_BLUE,
        payloadText: GRAY,
        addNewBorder: ROYAL_BLUE,
        addNewText: RIBBON_BLUE,
      },
    },
    nlp: {
      menuButtonBackground: RIBBON_BLUE,
      menuButtonBackgroundHover: CADET_BLUE,
      menuButtonText: WHITE,
      subHeaderText: COMET_GREY,
      training: {
        menuItemBackground: PERMISSION_RED,
        buttonText: RIBBON_BLUE,
        buttonBackground: WHITE,
        buttonBackgroundHover: WHITE,
        mainSelectedBackground: WHITE,
        selectItemBorderRight: ATHENS_GRAY_DARK,
        descText: FIORD_BLUE,
        selectIconBackground: PERMISSION_RED,
        trainedIconBackground: MALACHITE_GREEN,
        trainedIconBorder: MALACHITE_GREEN,
        leftTabBackground: CORNFLOWER_BLUE,
        leftTabText: WHITE,
        rightTabBackground: WHITE,
        rightTabText: COMET_GREY,
        titleText: FIORD_BLUE,
        activeNlpBackground: CORNFLOWER_BLUE,
        activeNlpText: WHITE,
        defaultNlpBackground: WHITE,
        defaultNlpText: COMET_GREY,
        filterText: FIORD_BLUE,
      },
    },
    support: {
      tabBackground: WHITE,
      tabBackgroundActive: CORNFLOWER_BLUE,
      tabText: COMET_GREY,
      tabTextActive: WHITE,
      assignToMeButton: RIBBON_BLUE,
      assignToMeButtonText: WHITE,
      assignToButtonBorder: RIBBON_BLUE,
      assignToButton: WHITE,
      assignToButtonText: RIBBON_BLUE,
      atomButtons: {
        text: RIBBON_BLUE,
        background: WHITE,
        backgroundPressed: PERIWINKLE,
        border: PLATINUM,
        borderPressed: MERCURY,
        tooltipText: WHITE,
        tooltipBackground: MINE_SHAFT,
      },
      closeButtonBorder: RIBBON_BLUE,
      closeButtonBackground: WHITE,
      closeButtonText: RIBBON_BLUE,
      closeButtonBorderDisabled: RIBBON_BLUE,
      postbackModal: {
        popoverBackgroundColor: WHITE,
        atomNameBorder: ATHENS_GRAY_DARK,
        menuTitleText: OXFORD_BLUE,
        primaryText: COMET_GREY,
        buttonType: SHARK_BLACK,
        secondaryText: WHITE,
        tooltipBackground: MINE_SHAFT,
        tooltipText: WHITE,
        attributeNameBorder: CORNFLOWER_BLUE,
        attributeNameBackground: CORNFLOWER_BLUE,
        attributeNameScrollbar: GAINSBORO,
        attributeValueBorder: ATHENS_GRAY_DARK,
        checkBox: {
          checkedIcon: CORNFLOWER_BLUE,
          notCheckedIcon: WHITE,
          checkedMarkIcon: WHITE,
          notCheckedMarkIcon: WHITE,
          checkedBorder: CORNFLOWER_BLUE,
          notCheckedBorder: CORNFLOWER_BLUE,
        },
      },
      quickReply: {
        background: WHITE,
        backgroundSubmit: RIBBON_BLUE,
        backgroundPressed: '#CADFFF',
        border: RIBBON_BLUE,
        borderSubmit: RIBBON_BLUE,
        borderPressed: '#CADFFF',
        text: RIBBON_BLUE,
        textSubmit: WHITE,
      },
    },
  },
})
