export const styles = ({ tabs: { support: { atomButtons } } }) => ({
  wrap: {
    width: 300,
  },
  button: {
    borderTop: '1px solid',
    borderColor: atomButtons.border,
    height: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: atomButtons.text,
    fontWeight: 'bold',
  },
  title: {
    textAlign: 'center',
    flexGrow: 1,
    marginLeft: '21px',
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '5px',
  },
  tooltip: {
    backgroundColor: atomButtons.tooltipBackground,
    color: atomButtons.tooltipText,
    fontSize: '16px',
    borderRadius: '10px',
    padding: '8px',
  },
})
