export const styles = () => ({
  userMainInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  userNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
    width: 'calc(100% - 46px)',
  },
  name: {
    fontSize: '14px',
    lineHeight: '17px',
    marginBottom: '0',
    textDecoration: 'none !important',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: '#3A3F62',
  },
  supportIcon: {
    width: 12,
    height: 12,
    marginLeft: 5,
  },
  lastMsgTime: {
    color: '#B0B2C0',
    fontSize: 12,
    fontWeight: 400,
    marginLeft: 5,
    width: 40,
    textAlign: 'right',
  },
  outOfWorkingHoursIcon: {
    width: 20,
    height: 20,
    marginLeft: 5,
  },
  flag: {
    marginLeft: 5,
  },
})
